import React, { useState } from 'react';
import { Col, Drawer, Form, Input, Row} from 'antd';
import { ReactComponent as Cancel } from '../../assets/images/icons/x.svg';
import map_locationIcon from '../../assets/images/restaurant/map_locationIcon.svg';
import { useNavigate } from 'react-router-dom';


export const CheckoutDrawer = ({ visible, onClose }) => {
    const navigate = useNavigate()
  return (
    <div>
  
        <Drawer
            width={720}
            onClose={onClose}
            open={visible}
            className='checkoutDrawer'
        >
            <div className="w-full px-8 py-4">
                <div className="drawer_header py-3 border-b-2 border-[#959595] flex justify-between">
                    <h2 className='text-4xl' >Change delivery address</h2>
                    <Cancel onClick={() => onClose()} className="cancel cursor-pointer " />
                </div>
                <Row justify={'center'}>
                    <Col xs={24}>
                        <Form layout="vertical">
                            <div className="my-3 text-7xl">
                                <Form.Item className='font-bold ' label="Name">
                                    <Input className='py-4 px-3' placeholder="Name" />
                                </Form.Item>   
                            </div>
                            <div className="my-3">
                                <Form.Item className='font-bold ' label="Phone number">
                                    <div className="flex space-x-2">
                                        <Input placeholder="Country Code" className="w-1/4" />
                                        <Input placeholder="Phone Number" className="w-3/ py-4 px-3 " />
                                    </div>
                                </Form.Item>  
                            </div>
                            <div className="my-3">
                                <Form.Item className='font-bold ' label="Email">
                                    <Input className='py-4 px-3' placeholder="Email Address" />
                                </Form.Item>   
                            </div>
                            <div className="my-3">
                                <Form.Item className='font-bold ' label="Address">
                                    <Input className='py-4 px-3' placeholder="Address"  suffix={<img src={map_locationIcon} alt='location icon' />}  />
                                </Form.Item>   
                            </div>
                            <div className="my-3">
                                <Form.Item className='font-bold ' label="Apartment No. (Optional)">
                                    <Input className='py-4 px-3' placeholder="Apartment Number"  />
                                </Form.Item>   
                            </div>
                            <div className="my-3">
                                <Form.Item className='font-bold ' label="Landmark (optional) ">
                                    <Input className='py-4 px-3' placeholder="Landmark"/>
                                </Form.Item>   
                            </div>
                            <div className="my-3 flex justify-end">
                                <button onClick={() => navigate('/restaurant/1/checkout_successful') } className="bg-[#44843F] text-white rounded-3xl border-2 border-black px-40 my-10 py-3">
                                    <p>Save</p>
                                </button>
                            </div>
                            
                            
                        </Form> 
                    </Col>
                </Row>
            </div>


      </Drawer>
    </div>
  )
}
