import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import under_constructionVid from '../assets/images/restaurant/under_constructionVid.mp4';


export const UnderConstructionModal = () => {
  const [partnerModalOpen, setPartnerModalOpen] = useState(false);

  const handlePartnerCancel = () => {
    setPartnerModalOpen(false);
  };

  useEffect(() => {
    setPartnerModalOpen(true)
  }, [])

  return (
    <Modal
    open={partnerModalOpen }
    footer={false}
    onCancel={handlePartnerCancel}
    className="underConstructionModal"
  >
    <div className="flex flex-col justify-center mt-10 items-center">
      <h2 className="text-green-800 font-bold flex items-center my-4  text-5xl ">We're Cooking Something Special!</h2>
      <p className="text-4xl font-bold">Under Construction, But Deliciously So!</p>
    </div>
    <div className="flex flex-col justify-center items-center">
      <video
        src={under_constructionVid}
        autoPlay
        loop
        muted
        playsInline
        className="w-full h-full my-5 rounded-lg shadow-md"
      />		
    </div>
  </Modal>
  )
}
