import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { _change_password, _generate_otp, _get_profile, _update_trans_pin, _verify_otp } from "../../../../common/axios_services";
import { LoadingOutlined } from '@ant-design/icons';
import { fetchData, profileData } from "../../../../slices/profileSlice";
import TopNav from '../../../../components/top-nav';
import { Button, Form, Input, Modal, Spin, notification } from "antd";



const UpdateTransPin = () => {
    const [VerActivated, setVerActivated] = useState(false);
    const [updatePinVisible, setUpdatePinVisible] = useState(false);
    const [updateCount, setUpdateCount] = useState(0);
    const [loadingOTPData, setLoadingOTPData] = useState(false);
    const [otp, setOtp] = useState(false);
    const [verifiedOtp, setVerifiedOtp] = useState(false);
    const [updatingPin, setUpdatingPin] = useState(false);
    const [transactionPinUpdateDisplay, setTransactionPinUpdateDisplay] = useState(0);
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    // const [loading, setLoading] = useState(false);
    const [reGen, setReGen] = useState(false);
    const [userOTP, setUserOTP] = useState('');
    const [revealPinMessage, setRevealPinMessage] = useState('');


    const openNotificationWithIcon = (type, title, message) => {
        notification[type]({
            placement: 'bottomRight',
            message: title ? title : '',
            description: message ? message : "",
        });
    };

    // const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;
    const newAntIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;
    const navigate = useNavigate()
    const dispatch = useDispatch()


    const fetchUser = async () => {
        try {
            const user_profile = await _get_profile();
            dispatch(fetchData(true))
            dispatch(profileData(user_profile?.data?.data))
            setVerActivated(user_profile?.data?.data?.login_2fa)
            if (user_profile?.data?.data?.email_otp) {
                setOtp(user_profile?.data?.data?.email_otp)
            }
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/signin?redir=loyalty`)
                }
                dispatch(profileData(false))
                dispatch(fetchData(false))
            } else {
            }
        }
    }

    useEffect(() => {
        fetchUser();
    }, [updateCount])

    const updatePin = async (value) => {
        setRevealPinMessage('');
        if (value.new_pin !== value.confirm_pin) {
            setRevealPinMessage('New pin and confirm pin are not equal')
        } else {
            setUpdatingPin(true)
            try {
                const changeTransPin = await _update_trans_pin({ new_pin: value.new_pin, confirm_new_pin: value.confirm_pin, otp: userOTP })
                setLoadingSpinner(false);
                openNotificationWithIcon(changeTransPin?.data?.status, changeTransPin?.data?.title, changeTransPin?.data?.message);
                setUpdatingPin(false);
                setLoadingSpinner(false);
                setTransactionPinUpdateDisplay(0);
                setUpdatePinVisible(false);
                setVerifiedOtp(false)
            } catch (err) {
                if (err.response) {
                    if (err.response.data.message === "Unauthorized") {
                        openNotificationWithIcon('error', err?.response?.data?.title, err?.response?.data?.message);
                        navigate('/signin');
                    }
                    openNotificationWithIcon('error', err?.response?.data?.title, err?.response?.data?.message);
                } else {
                    openNotificationWithIcon('error', "Something went wrong", err?.message);
                }
                setUpdatingPin(false);
                setLoadingSpinner(false);
            }
        }
    }

    const genOtp = async () => {
        setLoadingOTPData(true)
        try {
            const generate_otp = await _generate_otp();
            setLoadingOTPData(false);
            setUpdateCount(updateCount + 1);
            setOtp(true);
            setReGen(false)
            openNotificationWithIcon('success', generate_otp?.data?.title, generate_otp?.data?.message);
        } catch (err) {
            setLoadingOTPData(false)
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
                    navigate('/signin')
                }
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message);
            }
        }
    }

    const VerifyOtp = async (values) => {
        setLoadingOTPData(true)
        try {
            const verifyOtp = await _verify_otp({ otp: values.otp });
            setLoadingOTPData(false);
            setVerifiedOtp(true);
            setUserOTP(values.otp);
            setTransactionPinUpdateDisplay(1);
            openNotificationWithIcon('success', verifyOtp?.data?.title, verifyOtp?.data?.message);
        } catch (err) {
            setLoadingOTPData(false)
            setVerifiedOtp(false);
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
                    navigate('/signin')
                } else {
                    if (err.response.data.message.includes("expired")) {
                        setOtp(false)
                        setReGen(true)
                    }
                    setReGen(false)
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
                }
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message);
            }
        }
    }







  return (
    <div>
        <Spin spinning={loadingSpinner} indicator={newAntIcon}>
            <div className="profile-display settings-display form-page">
                <TopNav title="Update Transaction Code" />
                <div className="display-main-content white-b">
                    <div className="contain">
                        <div className="mt_4">
                            <div>
                                <div>
                                    {/* {errorMessage.length ? <p className="form-error mb_3">{errorMessage}</p> : ""} */}
                                </div>
                                <div className="accountProtection  my-20 flex justify-center items-center">
                                    <form>
                                        <div className="sh">
                                            <div className="form-group ">
                                                <button className="py-3 px-5 w-full font-gilroyBold text-[15px] rounded-xl bg-[green] text-white " type="button" onClick={() => { setUpdatePinVisible(true); genOtp() }}>Update Transaction Code</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Spin>
        <Modal centered={true} className="airtimeModal" open={updatePinVisible}   maskClosable={false} footer={null} >
            <div>
                {verifiedOtp ?
                    <div className="">
                        <h4 className="modal-title">Update Transaction Code</h4>
                        <div style={{ marginTop: '5%' }}></div>
                        {revealPinMessage ? <p className="error-message">{revealPinMessage}</p> : ''}
                        <Form layout="vertical" style={{ maxWidth: 600 }} onFinish={updatePin} autoComplete="off">
                            <Form.Item label="New Pin" name="new_pin">
                                <Input.Password />
                            </Form.Item>
                            <Form.Item label="Confirm Pin" name="confirm_pin">
                                <Input.Password />
                            </Form.Item>
                            <div className="d-flex  flex gap-5" style={{ gap: "5px" }}>
                                {updatingPin ?
                                    <Button type="primary" disabled loading>Updating Pin</Button> :
                                    <Button type="primary" htmlType="submit">Update Pin</Button>
                                }
                                <Button onClick={() => { setUpdatePinVisible(false); setVerifiedOtp(false) }}>Cancel</Button>
                            </div>
                        </Form>
                    </div>
                    :
                    <div className="">
                        <h4 className="modal-title">Generate Otp</h4>
                        <p className='text-3xl' >An Otp would been sent to your email.</p>
                        <div style={{ marginTop: '5%' }}></div>
                        {revealPinMessage ? <p className="error-message">{revealPinMessage}</p> : ''}
                        <Form layout="vertical" style={{ maxWidth: 600 }} onFinish={VerifyOtp} autoComplete="off">
                            <Form.Item label="Otp" name="otp">
                                {otp ?
                                    <Input placeholder="Enter Otp" /> :
                                    <Input placeholder="Enter Otp" disabled />
                                }
                            </Form.Item>
                            <div className="d-flex flex gap-5" style={{ gap: "5px" }}>
                                {otp ?
                                    <>
                                        {loadingOTPData ? (
                                            <Button type="primary" loading disabled>
                                                Verifying Otp
                                            </Button>
                                        ) : reGen ? (
                                            <Button type="primary" onClick={genOtp}>
                                                Generate Pin
                                            </Button>
                                        ) : (
                                            <Button type="primary" htmlType="submit">
                                                Verify Otp
                                            </Button>
                                        )}

                                    </> :
                                    <>
                                        {loadingOTPData ?
                                            <Button type="primary" loading disabled>Generating Pin</Button> :
                                            <Button type="primary" onClick={genOtp}>Generate Pin</Button>
                                        }
                                    </>
                                }
                                <Button onClick={() => { setUpdatePinVisible(false); setVerifiedOtp(false) }}>Cancel</Button>
                            </div>
                        </Form>
                    </div>
                }
            </div>
        </Modal>
        
    </div>
  )
}

export default UpdateTransPin