import { Button, Card, Col, Row } from 'antd';
import React from 'react';
import restaurant_food from '../assets/images/homepage/restaurant_food.png';
import { useNavigate } from 'react-router-dom';

export const FoodImageArea = () => {
    const navigate = useNavigate()
  return (
        // <div className="lg:p-8  ">
            <div className='bg-white pb-36 px-3 py-2 rounded-3xl '>
                <div className="align-center mt-8  my-5">
                    <div>
                        <h1 className='text-5xl text-[#000000D9]' >Just for you! </h1>
                        <p className='font-gilroyMedium' style={{ fontSize: '1.5rem', fontWeight: "medium", color: "#959595" }}>Based on your frequent orders</p>
                    </div>
                    <Button type="link">View all</Button>
                </div>
                <div className="hidden mt-8 md:flex">
                    <Row gutter={[32, 32]} style={{ width: "100%", display: 'flex', alignItems: 'stretch', margin: "0px" }}>
                        <Col xs={24} sm={12} md={8} style={{ display: 'flex' }}>
                            <Card className='restaurant-card cursor-pointer'  onClick={() => navigate('/restaurant/1')} >
                                <img src={restaurant_food} className='transition-transform duration-300 ease-in-out transform hover:scale-105' alt="Restaurant 1" />
                                <div className="align-center">
                                    <h3>Havey Restaurant</h3>
                                    <span>❤️</span>
                                </div>
                                <div className="align-center">
                                    <p style={{ fontSize: "12px" }}>30-50min</p>
                                    <span>⭐ 4.5</span>
                                </div>
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} md={8} style={{ display: 'flex' }}>
                            <Card className='restaurant-card cursor-pointer'  onClick={() => navigate('/restaurant/2')} >
                                <img src={restaurant_food} className='transition-transform duration-300 ease-in-out transform hover:scale-105 ' alt="Restaurant 2" />
                                <div className="align-center">
                                    <h3>Amala Buka</h3>
                                    <span>❤️</span>
                                </div>
                                <div className="align-center">
                                    <p style={{ fontSize: "12px" }}>30-50min</p>
                                    <span>⭐ 4.5</span>
                                </div>
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} md={8} style={{ display: 'flex' }}>
                            <Card className='restaurant-card cursor-pointer'  onClick={() => navigate('/restaurant/3')} >
                                <img src={restaurant_food} className='transition-transform duration-300 ease-in-out transform hover:scale-105 ' alt="Restaurant 3" />
                                <div className="align-center">
                                    <h3>Nimb’s Restaurant</h3>
                                    <span>❤️</span>
                                </div>
                                <div className="align-center">
                                    <p style={{ fontSize: "12px" }}>30-50min</p>
                                    <span>⭐ 4.5</span>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>


                {/* MOBILE SCREEN */}
                <div className="max-md:overflow-x-auto md:hidden max-md:flex max-md:space-x-4 max-md:px-2 ">
                    <Card className='restaurant-card w-[80%] flex-shrink-0'   onClick={() => navigate('/restaurant/1')} >
                        <img src={restaurant_food} alt="Restaurant 1" />
                        <div className="align-center">
                            <h3>Kippy’s Place</h3>
                            <span>❤️</span>
                        </div>
                        <div className="align-center">
                            <p style={{ fontSize: "12px" }}>30-50min</p>
                            <span>⭐ 4.5</span>
                        </div>
                    </Card>
                    <Card className='restaurant-card w-[80%] flex-shrink-0'   onClick={() => navigate('/restaurant/2')} >
                        <img src={restaurant_food} alt="Restaurant 1" />
                        <div className="align-center">
                            <h3>Kippy’s Place</h3>
                            <span>❤️</span>
                        </div>
                        <div className="align-center">
                            <p style={{ fontSize: "12px" }}>30-50min</p>
                            <span>⭐ 4.5</span>
                        </div>
                    </Card>
                    <Card className='restaurant-card w-[80%] flex-shrink-0'   onClick={() => navigate('/restaurant/2')} >
                        <img src={restaurant_food} alt="Restaurant 1" />
                        <div className="align-center">
                            <h3>Kippy’s Place</h3>
                            <span>❤️</span>
                        </div>
                        <div className="align-center">
                            <p style={{ fontSize: "12px" }}>30-50min</p>
                            <span>⭐ 4.5</span>
                        </div>
                    </Card>
                    <Card className='restaurant-card w-[80%] flex-shrink-0'   onClick={() => navigate('/restaurant/3')} >
                        <img src={restaurant_food} alt="Restaurant 1" />
                        <div className="align-center">
                            <h3>Kippy’s Place</h3>
                            <span>❤️</span>
                        </div>
                        <div className="align-center">
                            <p style={{ fontSize: "12px" }}>30-50min</p>
                            <span>⭐ 4.5</span>
                        </div>
                    </Card>
                </div>
                
            </div>
        // </div>
    )
}
