import { Col, Row } from 'antd';
import React, { useState } from 'react';
import location_pin from "../assets/images/restaurant/location_red.svg";
import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons';


export const LocationWrapper = () => {
    const [isClicked, setIsClicked ] = useState(false)

    const handleLocationWrapperSearch = () => {
        setIsClicked(!isClicked)
    } 

  return (
    <div className='wrapperBg '>
        <Row justify={'middle'} className='h-[100%]'>
            <Col xs={24}>
                <div className="items-center hidden md:flex flex-wrap gap-2 justify-around px-10 ">
                    <div className="flex gap-2">
                        <span>
                            <img className='' src={location_pin} alt="" />
                        </span>   
                        <select className='w-full bg-transparent focus:outline-none text-3xl'>
                            <option value="">Select Location</option>
                            <option value="">Lekki Lagos</option>
                        </select>
                    </div>
                    <div>
                        <p className='text-[#959595]' >Lekki Phase 1, Lagos</p>
                    </div>
                    <div className="flex justify-between border-2 border-[#00000073] w-1/2 rounded-full  py-4 px-5 bg-transparent ">
                        <input type="text" className='w-full bg-transparent focus:outline-none text-3xl' placeholder='Search restaurants'  />
                        <SearchOutlined style={{ fontSize: '22px'}}  />
                    </div>
                </div>
                <div className="items-center md:hidden  gap-2 justify-around px-10 ">
                    <div className="flex justify-between items-center">
                        <div className={` ${isClicked ? 'hidden' : 'flex'} flex-col `}>
                            <div className="flex gap-3">
                                <span>
                                    <img className='' src={location_pin} alt="" />
                                </span>   
                                <select className='w-full bg-transparent focus:outline-none text-3xl'>
                                    <option value="">Select Location</option>
                                    <option value="">Lekki Lagos</option>
                                </select>
                            </div>
                            <div className='mt-3' >
                                <p className='text-[#959595]' >Lekki Phase 1, Lagos</p>
                            </div>
                        </div>

                        <div className={` ${isClicked ? 'flex justify-between border-2 border-[#00000073] w-full rounded-full  py-4 px-5 bg-transparent' : ''} `}>
                            <input type="text" className={` ${isClicked ? 'block' : 'hidden'}   w-full bg-transparent focus:outline-none text-3xl`} placeholder='Search restaurants'  />
                            {
                             isClicked ? <CloseCircleOutlined style={{ fontSize: '22px' }} onClick={handleLocationWrapperSearch} /> :
                             <SearchOutlined style={{ fontSize: '22px'}} onClick={handleLocationWrapperSearch}  />
                            }
                        </div>
                     
                    </div>
                </div>
            </Col>
        </Row>
        
    </div>
  )
}
