import React from 'react';
import { Tabs, Card, Button, Row, Col } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import restaurant_food from '../../assets/images/homepage/restaurant_food.png';




const { TabPane } = Tabs;

// Card Component
const FoodCard = ({ item }) => (
  <Card
    hoverable
    className="max-w-xs rounded-xl shadow-lg overflow-hidden  border-2 hover:border-black"
    cover={<img src={item.image} alt={item.name} />}
  >
    <div className="p-4">
      <h3 className="font-bold text-2xl">{item.name}</h3>
      <p className="text-gray-600 text-xl">{item.description}</p>
      <div className="flex justify-between items-center mt-4">
        <span className="text-gray-400 line-through ">{item.originalPrice}</span>
        <span className="text-black font-bold">{item.discountedPrice}</span>
        <div className="hidden md:flex items-center">
          <Button shape="circle" icon={<MinusOutlined />} size="small" />
          <span className="px-2">1</span>
          <Button shape="circle" icon={<PlusOutlined />} size="small" />
        </div>
       
      </div>
      <div className="flex md:hidden justify-between items-center mt-2">
        <Button shape="circle" icon={<MinusOutlined />} size="small" />
        <span className="px-2">1</span>
        <Button shape="circle" icon={<PlusOutlined />} size="small" />
      </div>

    </div>
  </Card>
);

// Content Component for each tab
const TabContent = ({ items }) => (

  <div className="grid xl:grid-cols-4 lg:grid-cols-3  grid-cols-2 gap-y-16 gap-6">
    {items.map((item, index) => (
      <FoodCard key={index} item={item} />
    ))}
  </div>
);

export const ImageTray = () => {
    const data = {
        All: [
          {
            name: 'Fried Rice & Chicken',
            description: '1 portion Fried Rice and...',
            image: restaurant_food,
            originalPrice: '₦1200',
            discountedPrice: '₦1000',
          },
          {
            name: 'Fried Rice & Chicken',
            description: '1 portion Fried Rice and...',
            image: restaurant_food,
            originalPrice: '₦1200',
            discountedPrice: '₦1000',
          },
          {
            name: 'Fried Rice & Chicken',
            description: '1 portion Fried Rice and...',
            image: restaurant_food,
            originalPrice: '₦1200',
            discountedPrice: '₦1000',
          },
          {
            name: 'Fried Rice & Chicken',
            description: '1 portion Fried Rice and...',
            image: restaurant_food,
            originalPrice: '₦1200',
            discountedPrice: '₦1000',
          },
          {
            name: 'Fried Rice & Chicken',
            description: '1 portion Fried Rice and...',
            image: restaurant_food,
            originalPrice: '₦1200',
            discountedPrice: '₦1000',
          },
          {
            name: 'Fried Rice & Chicken',
            description: '1 portion Fried Rice and...',
            image: restaurant_food,
            originalPrice: '₦1200',
            discountedPrice: '₦1000',
          },
          {
            name: 'Fried Rice & Chicken',
            description: '1 portion Fried Rice and...',
            image: restaurant_food,
            originalPrice: '₦1200',
            discountedPrice: '₦1000',
          },
          {
            name: 'Fried Rice & Chicken',
            description: '1 portion Fried Rice and...',
            image: restaurant_food,
            originalPrice: '₦1200',
            discountedPrice: '₦1000',
          },
        ],
        Swallow: [
          {
            name: 'Pounded Yam & Egusi',
            description: '1 portion Pounded Yam with Egusi',
            image: restaurant_food,
            originalPrice: '₦1500',
            discountedPrice: '₦1300',
          },
        ],
        Continental: [
          {
            name: 'Spaghetti Bolognese',
            description: '1 portion Spaghetti Bolognese',
            image: restaurant_food,
            originalPrice: '₦1800',
            discountedPrice: '₦1600',
          },
        ],
        Breakfast: [
          {
            name: 'Pancakes & Syrup',
            description: '1 portion Pancakes with Syrup',
            image: restaurant_food,
            originalPrice: '₦800',
            discountedPrice: '₦700',
          },
        ],
    };
  return (
    <>
        <Tabs defaultActiveKey="All">
            {Object.keys(data).map((key) => (
            <TabPane tab={key} key={key}>
                <TabContent items={data[key]} />
            </TabPane>
            ))}
        </Tabs>

    </>

  )
}
