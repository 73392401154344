import "../../../assets/css/mini.css";

import React, { useState, useEffect } from "react";

import { Divider, notification, Spin } from "antd";
import { DateTime } from 'luxon';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { LoadingOutlined } from "@ant-design/icons";


import { _complete_wise9ja_payment, _confirm_user_transfer_pin, _fetch_bonus_account_history, _fetch_user_wallet_data, _fetch_user_wise9ja_data, _fetch_wise9ja_plans, _get_all_account_details, _get_all_transaction_data, _get_all_wise9ja, _get_profile, _get_user_account_history, _get_user_wallet_history, _wise9ja_topup } from '../../../common/axios_services';


import FooterDisplay from "../../../components/footer";
import TopNav from "../../../components/top-nav";
import WalletHistoryDetailPage from "../../../components/profile/history/wallet";


const Wise9jaPage = () => {

    const Navigate = useNavigate();
    const majorSpinnerIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    const [currentDispTab, setCurrentDispTab] = useState("wallet");
    const [loyaltyHis, setLoyaltyHis] = useState([]);
    const [transHis, setTransHis] = useState([]);
    const [totalTrans, setTotalTrans] = useState(0);

    const [wiseHis, setWiseHis] = useState([]);
    const [page, setPage] = useState(1);
    const [totalLoyalty, setTotalLoyalty] = useState(0);
    const [per_page, setPer_page] = useState(10);
    const [total_trans, setTotal_trans] = useState(0);
    const [fetchingLoyalty, setFetchingLoyalty] = useState(true)
    const [total_wise, setTotal_wise] = useState(0);
    const [pageLoy, setPageLoy] = useState(1);
    const [pageSizeTrans, setPageSizeTrans] = useState(20);
    const [pageSizeLoy, setPageSizeLoy] = useState(20);
    const [displayMode, setDisplayMode] = useState("content");
    const [historyData, setHistoryData] = useState({});

    const [bonusHis, setBonusHis] = useState([]);
    const [pageSizeBonus, setPageSizeBonus] = useState(20);
    const [totalBonus, setTotalBonus] = useState(0);

    const [majorPageSpinner, setMajorPageSpinner] = useState(true);

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            description: message,
        });
    };

    const fetchUserWalletPayments = async () => {
        try {
            const getPayment = await _get_user_wallet_history({ page: pageLoy, page_size: pageSizeLoy });
            setLoyaltyHis(getPayment.data.data);
            console.log(getPayment)
            setFetchingLoyalty(false);
            setTotalLoyalty(getPayment.data.meta.total);
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token');
                    Navigate('/signin');
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', "An error occurred while loading loyalty data. Please reload page to try again")
            }
        }
    }
    const fetchUserTransactionData = async () => {
        try {
            let userWalletTrx = await _get_all_transaction_data({ page, page_size: pageSizeTrans });
            setTransHis(userWalletTrx.data.data);
            setTotalTrans(userWalletTrx.data.meta.total);
            setMajorPageSpinner(false);
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    Navigate('/signin');
                    openNotificationWithIcon('error', "User Authorization expired. Please log in to continue");
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
            setMajorPageSpinner(false);
        }
    }

    const fetchBonusAccountHistory = async () => {
        try {
            let userWalletTrx = await _fetch_bonus_account_history({ page, page_size: pageSizeBonus });
            setBonusHis(userWalletTrx.data.data);
            setTotalBonus(userWalletTrx.data.meta.total);
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    Navigate('/signin');
                    openNotificationWithIcon('error', "User Authorization expired. Please log in to continue");
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    const fetchUserTransData = async () => {
        try {
            let userWalletTrx = await _get_all_transaction_data({ page, pageSizeTrans });
            setTransHis(userWalletTrx.data.data);
            setTotalTrans(userWalletTrx.data.meta.total);
            setMajorPageSpinner(false);
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    Navigate('/signin');
                    openNotificationWithIcon('error', "User Authorization expired. Please log in to continue");
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
            setMajorPageSpinner(false);
        }
    }

    useEffect(() => {
        fetchBonusAccountHistory();
        fetchUserWalletPayments();
        fetchUserTransactionData();
    }, [])

    const handleHistoryDisplay = history => {
        setDisplayMode("history");
        setHistoryData(history);
    }

    const setDisplayModeToContent = () => {
        setDisplayMode("content");
    }

    return (
        <Spin indicator={majorSpinnerIcon} spinning={majorPageSpinner}>
            {
                displayMode === "content" ?
                    <div className="homepage wise9ja-disp display-page history">
                        <div className="">
                            <TopNav title="Your Wallet History" />
                        </div>
                        <div className="display-main-content mt_2">
                            <div className="content-display-content">
                                <div className="contain">
                                    <div className="top-selection-bar mt_2">
                                        <Swiper
                                            spaceBetween={5} slidesPerView="auto"
                                            loop={false} breakpoints={5}>
                                            <SwiperSlide key={`extra-data-cover-1`}>
                                                <div
                                                    onClick={() => { setCurrentDispTab("wallet") }}
                                                    className={`select-bar ${currentDispTab === "wallet" ? "active-bar" : ""}`}>
                                                    <div className="active-inner-text">
                                                        <h4>Wallet History</h4>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide key={`extra-data-cover-2`}>
                                                <div
                                                    onClick={() => { setCurrentDispTab("wise9ja") }}
                                                    className={`select-bar ${currentDispTab === "wise9ja" ? "active-bar" : ""}`}>
                                                    <div className="active-inner-text">
                                                        <h4>Transaction History</h4>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide key={`extra-data-cover-3`}>
                                                <div
                                                    onClick={() => { setCurrentDispTab("konnect") }}
                                                    className={`select-bar ${currentDispTab === "konnect" ? "active-bar" : ""}`}>
                                                    <div className="active-inner-text">
                                                        <h4>Bonus History</h4>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </div>
                                    <div className="wise9ja-action-bar">

                                    </div>
                                    <div className="mt_5 history-data-detail">
                                        <div className="contai">
                                            {
                                                currentDispTab === "wallet" ?
                                                    loyaltyHis.map((history, index) => (
                                                        <React.Fragment key={`loy-${index}`}>
                                                            <div
                                                                onClick={() => handleHistoryDisplay(history)}
                                                                className={`current-history-display ${index === 0 ? "first-history-disp" : ""}`} key={`loy-${index}`}>
                                                                <div className="disp-category">
                                                                    {/* <h3>{history?.service?.slice(0, 1)?.toUpperCase()}T</h3> */}
                                                                    {
                                                                        history?.payment_type !== "credit" ?
                                                                            <ion-icon name="arrow-forward-circle-outline"></ion-icon>
                                                                            :
                                                                            <ion-icon class="down" name="arrow-down-circle-outline"></ion-icon>
                                                                    }
                                                                </div>
                                                                <div>
                                                                    <h3>{history?.service?.slice(0, 1)?.toUpperCase()}{history?.service?.slice(1)} Transaction</h3>
                                                                    <ul>
                                                                        <li>{DateTime.fromISO(history?.timestamp).toLocaleString(DateTime.DATE_HUGE)};</li>
                                                                        <li>{DateTime.fromISO(history.timestamp).toLocaleString(DateTime.TIME_24_SIMPLE)}</li>
                                                                    </ul>
                                                                </div>
                                                                <div>
                                                                    <h3 className={`history-amount ${history?.payment_type === "credit" ? "green" : "red"} `}>{history?.payment_type === "credit" ? "+" : "-"}<span className="currency">NGN</span><NumberFormat thousandSeparator={true} prefix={''}
                                                                        className="numeric" displayType={'text'} value={Math.round(Number(history?.total_amount) * 1).toFixed(2)} /></h3>
                                                                    <p className={`status`}>{history?.payment_type}</p>
                                                                </div>
                                                            </div>
                                                            <Divider style={{ margin: "20px 0px" }} />
                                                        </React.Fragment>
                                                    ))
                                                    :
                                                    currentDispTab === "wise9ja" ?
                                                        transHis.map((history, index) => (
                                                            <React.Fragment key={`trans-${index}`}>
                                                                <div
                                                                    onClick={() => handleHistoryDisplay(history)}
                                                                    className={`current-history-display ${index === 0 ? "first-history-disp" : ""}`} key={`trans-${index}`}>
                                                                    <div className="disp-category">
                                                                        {/* <h3>{history?.service?.slice(0, 1)?.toUpperCase()}T</h3> */}
                                                                        {
                                                                            history?.payment_type !== "credit" ?
                                                                                <ion-icon name="arrow-forward-circle-outline"></ion-icon>
                                                                                :
                                                                                <ion-icon class="down" name="arrow-down-circle-outline"></ion-icon>
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        <h3>{history?.service?.slice(0, 1)?.toUpperCase()}{history?.service?.slice(1)} Transaction</h3>
                                                                        <ul>
                                                                            <li>{DateTime.fromISO(history?.timestamp).toLocaleString(DateTime.DATE_HUGE)};</li>
                                                                            <li>{DateTime.fromISO(history.timestamp).toLocaleString(DateTime.TIME_24_SIMPLE)}</li>
                                                                        </ul>
                                                                    </div>
                                                                    <div>
                                                                        <h3 className={`history-amount ${history?.payment_type === "credit" ? "green" : "red"} `}>{history?.payment_type === "credit" ? "+" : "-"}<span className="currency">NGN</span><NumberFormat thousandSeparator={true} prefix={''}
                                                                            className="numeric" displayType={'text'} value={Math.round(Number(history?.total_amount) * 1).toFixed(2)} /></h3>
                                                                        <p className={`status`}>{history?.payment_type}</p>
                                                                    </div>
                                                                </div>
                                                                <Divider style={{ margin: "20px 0px" }} />
                                                            </React.Fragment>
                                                        ))
                                                        :
                                                        bonusHis.map((history, index) => (
                                                            <React.Fragment key={`trans-${index}`}>
                                                                <div
                                                                    onClick={() => handleHistoryDisplay(history)}
                                                                    className={`current-history-display ${index === 0 ? "first-history-disp" : ""}`} key={`trans-${index}`}>
                                                                    <div className="disp-category">
                                                                        {/* <h3>{history?.service?.slice(0, 1)?.toUpperCase()}T</h3> */}
                                                                        {
                                                                            history?.payment_type !== "credit" ?
                                                                                <ion-icon name="arrow-forward-circle-outline"></ion-icon>
                                                                                :
                                                                                <ion-icon class="down" name="arrow-down-circle-outline"></ion-icon>
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        <h3>{history?.service?.slice(0, 1)?.toUpperCase()}{history?.service?.slice(1)} Transaction</h3>
                                                                        <ul>
                                                                            <li>{DateTime.fromISO(history?.timestamp).toLocaleString(DateTime.DATE_HUGE)};</li>
                                                                            <li>{DateTime.fromISO(history.timestamp).toLocaleString(DateTime.TIME_24_SIMPLE)}</li>
                                                                        </ul>
                                                                    </div>
                                                                    <div>
                                                                        <h3 className={`history-amount ${history?.payment_type === "credit" ? "green" : "red"} `}>{history?.payment_type === "credit" ? "+" : "-"}<span className="currency">NGN</span><NumberFormat thousandSeparator={true} prefix={''}
                                                                            className="numeric" displayType={'text'} value={Math.round(Number(history?.amount) * 1).toFixed(2)} /></h3>
                                                                        <p className={`status`}>{history?.payment_type}</p>
                                                                    </div>
                                                                </div>
                                                                <Divider style={{ margin: "20px 0px" }} />
                                                            </React.Fragment>
                                                        ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <FooterDisplay />
                    </div>
                    :
                    <div>
                        <WalletHistoryDetailPage historyData={historyData} displayModeControl={setDisplayModeToContent} />
                    </div>
            }
        </Spin>
    )
}

export default Wise9jaPage;