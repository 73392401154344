import { LocationWrapper } from "../../components/LocationWrapper";
import { Col, Row } from "antd";
import { RestaurantCardDeals } from "../../components/RestaurantCardDeals";
import { FoodImageArea } from "../../components/FoodImageArea";
import { UnderConstructionModal } from "../../components/UnderConstructionModal";
import FooterDisplay from "../../components/footer";



const Restaurant = () => {
  return (
    <>
      {/*  UNDER CONSTRUCTION POPUP */}
      <UnderConstructionModal />

      <div style={{ backgroundColor: '#F2F2F2' }}>
        <LocationWrapper />
        {/* header Banner */}
        <div className="headerBg w-full mt-2 ">
          <Row
          align={"middle"}
          className="h-full w-full px-12 py-36 "
          >
            <Col xs={24} md={12}>
                <div className=" max-md:text-center flex items-center text_h3 ">
                  <p className="md:text-8xl xl:text-9xl text-8xl text_h3 text-white ">
                    Restaurants
                  </p>
                </div>
            </Col>
          </Row>
        </div>
        {/* section 3 */}
        <RestaurantCardDeals />

        {/* BANNER */}
        {/* <Row justify={"center"} className="w-full h-full  ">
          <Col xs={24} lg={22} xl={23}>
            <div className="restaurantBanner w-full">
            </div>
          </Col>
        </Row> */}

        {/* Food Image Area */}
        <div className="mb-24">
          <Row justify={"center"}>
            <Col xs={22} lg={23}>
              <div className="w-full">
                <FoodImageArea />   
              </div>
            </Col>
          </Row>
        </div>

        <FooterDisplay/>


      </div>
    </>
  )
}

export default Restaurant;