import { Card } from 'antd';
import React, { useState } from 'react';
import empty_cart from '../../assets/images/restaurant/empty_cart.svg';
import replicate from '../../assets/images/restaurant/replicate.svg';
import deleteIcon from '../../assets/images/restaurant/delete.svg';
import sendIcon from '../../assets/images/restaurant/send-o.svg';
import giftIcon from '../../assets/images/restaurant/gift.svg';
import restaurant_food from '../../assets/images/homepage/restaurant_food.png';
import { useNavigate } from 'react-router-dom';




export const CheckoutSide = () => {
    const navigate = useNavigate()
    const [isFlipped, setIsFlipped] = useState(false);

    const handleFlip = () => {
      setIsFlipped(true);
    };
  return (
        <>
            <div className={`flip-container mt-24 md:mt-0 ${isFlipped ? 'flipped' : ''}`}>
                <div className="flippable-card">
                {/* Front Side (Empty Cart) */}
                <div className="card-front">
                    <div className="w-full lg:mt-24">
                    <Card bordered={false} className="flex justify-center items-center py-14 bg-[#FDF4D7] rounded-[32px] border-2 border-black">
                        <div className="flex flex-col justify-center items-center gap-5">
                        <img src={empty_cart} alt="empty_cart" />
                        <p className="text-[#959595]">Your cart is empty</p>
                        <button onClick={handleFlip} className="bg-transparent w-full rounded-xl border-2 border-black px-10 my-10 py-3">
                            Go to Checkout
                        </button>
                        </div>
                    </Card>
                    </div>
                </div>

                {/* Back Side (Order Summary) */}
                <div className="card-back">
                    <div className="w-full lg:mt-24">
                        <Card bordered={false} className="flex-col px-10 items-center py-14 bg-[#f5fff5] rounded-[32px] border-2 border-black">
                            <div className="divide-y-2">
                                <div className="rest_header my-3">
                                    <h2 className='text-3xl text-black' >Order Summary</h2>
                                </div>
                                <div className="order_item ">
                                    <div className="flex items-center gap-4 py-3 my-3  ">
                                        <img src={restaurant_food} alt="restaurant_logo" className="w-28 h-28 rounded-full" />
                                        <div>
                                        <p className="text-2xl text-black font-semibold">Havey’s Restaurant - Lekki</p>
                                        <p className="text-xl text-[#959595]">2 Items •  ₦ 6000</p>
                                        </div>
                                    </div>
                                    {/* Order Items */}
                                    <div className="flex flex-col gap-3 py-3 ">
                                        <div className="flex justify-between">
                                            <p className="font-bold text-black text-3xl">Pack 1</p>
                                            <div className="flex gap-5">
                                                <img src={replicate} alt=''  />
                                                <img src={deleteIcon} alt=''  />
                                            </div>
                                        </div>
                                        <div className="flex justify-between mt-3">
                                            <p className="text-3xl text-black font-bold ">Fried Rice & Chicken - 1 portion and half chicken</p>
                                            <div className="flex items-center border bg-transparent py-2 px-4 gap-2">
                                                <button className="text-lg">-</button>
                                                <span>1</span>
                                                <button className="text-lg">+</button>
                                            </div>
                                        </div>
                                        <p className="text-[#959595]">₦2000</p>
                                    </div>
                                    {/* Your Selections */}
                                    <div className="bg-[#E4E4E4] rounded-3xl p-3  border border-black ">
                                        <p className="text-black font-semibold">Your Selections</p>
                                        <div className="flex justify-between text-[#959595] text-2xl mt-1">
                                        <span>Option 1</span>
                                        <span>+₦1000</span>
                                        </div>
                                        <div className="flex justify-between text-[#959595] text-2xl mt-1">
                                        <span>Option 2</span>
                                        <span>+₦500</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="order_item my-10">
                                    {/* Order Items */}
                                    <div className="flex flex-col gap-3 py-3 mt-3  ">
                                        <div className="flex justify-between">
                                            <p className="font-bold text-black text-3xl">Pack 2</p>
                                            <div className="flex gap-5">
                                                <img src={replicate} alt=''  />
                                                <img src={deleteIcon} alt=''  />
                                            </div>
                                        </div>
                                        <div className="flex justify-between mt-3">
                                            <p className="text-3xl text-black font-bold ">Jollof Rice & Beef - 1 portion</p>
                                            <div className="flex items-center border bg-transparent py-2 px-4 gap-2">
                                                <button className="text-lg">-</button>
                                                <span>1</span>
                                                <button className="text-lg">+</button>
                                            </div>
                                        </div>
                                        <p className="text-[#959595]">₦2000</p>
                                    </div>
                                    {/* Your Selections */}
                                    <div className="bg-[#E4E4E4] rounded-3xl p-3  border border-black ">
                                        <p className="text-black font-semibold">Your Selections</p>
                                        <div className="flex justify-between text-[#959595] text-2xl mt-1">
                                        <span>Option 1</span>
                                        <span>+₦1000</span>
                                        </div>
                                        <div className="flex justify-between text-[#959595] text-2xl mt-1">
                                        <span>Option 2</span>
                                        <span>+₦500</span>
                                        </div>
                                    </div>
                                    <button onClick={handleFlip} className="bg-[#FDF4D7] w-full rounded-3xl border-2 border-black px-10 my-10 py-3">
                                        <p> + Add another pack</p>
                                    </button>
                                </div>
                                <div className="my-10">
                                    <div className="flex justify-between py-2">
                                        <p>Leave a message for the restaurant</p>
                                        <span>
                                            <img src={sendIcon} alt="" className='w-10 h-10'  />
                                        </span>
                                    </div>
                                </div>
                                <div className="my-10">
                                    <div className="flex justify-between py-2">
                                        <p>Send as Gift</p>
                                        <span>
                                            <img src={giftIcon} alt="" className='w-10 h-10'  />
                                        </span>
                                    </div>
                                </div>
                                <div className="my-10">
                                    <p className='text-lg text-center'>By proceeding, you agree to our <span className='text-[#44843F]'> Terms of use </span> and <span className='text-[#44843F]'>Privacy Policy</span> </p>
                                    <button onClick={() => {navigate('/restaurant/1/checkout')}} className="bg-[#44843F] text-white flex justify-between w-full rounded-3xl border-2 border-black px-10 my-10 py-3">
                                        <p>Checkout</p>
                                        <p> ₦5500</p>
                                    </button>
                                </div>

                            </div>
                            {/* Order Summary Header */}
                            
                        </Card>
                    </div>
                </div>
                </div>
            </div>
        </>


    )
}
